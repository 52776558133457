exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nostr-index-tsx": () => import("./../../../src/pages/nostr/index.tsx" /* webpackChunkName: "component---src-pages-nostr-index-tsx" */),
  "component---src-pages-team-b-index-tsx": () => import("./../../../src/pages/team/b/index.tsx" /* webpackChunkName: "component---src-pages-team-b-index-tsx" */),
  "component---src-pages-team-daniyal-index-tsx": () => import("./../../../src/pages/team/daniyal/index.tsx" /* webpackChunkName: "component---src-pages-team-daniyal-index-tsx" */),
  "component---src-pages-team-enes-index-tsx": () => import("./../../../src/pages/team/enes/index.tsx" /* webpackChunkName: "component---src-pages-team-enes-index-tsx" */),
  "component---src-pages-team-eugene-index-tsx": () => import("./../../../src/pages/team/eugene/index.tsx" /* webpackChunkName: "component---src-pages-team-eugene-index-tsx" */),
  "component---src-pages-team-freakoverse-index-tsx": () => import("./../../../src/pages/team/freakoverse/index.tsx" /* webpackChunkName: "component---src-pages-team-freakoverse-index-tsx" */),
  "component---src-pages-team-humble-goat-index-tsx": () => import("./../../../src/pages/team/humble_goat/index.tsx" /* webpackChunkName: "component---src-pages-team-humble-goat-index-tsx" */),
  "component---src-pages-team-otto-index-tsx": () => import("./../../../src/pages/team/otto/index.tsx" /* webpackChunkName: "component---src-pages-team-otto-index-tsx" */),
  "component---src-pages-team-stixx-index-tsx": () => import("./../../../src/pages/team/stixx/index.tsx" /* webpackChunkName: "component---src-pages-team-stixx-index-tsx" */),
  "component---src-pages-team-sync-index-tsx": () => import("./../../../src/pages/team/sync/index.tsx" /* webpackChunkName: "component---src-pages-team-sync-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

